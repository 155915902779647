import React from 'react';

import Form from '../Common/Form/Form';
import Input from '../Common/Input/Input';
import TextArea from '../Common/Input/TextArea';
import Button from '../Common/Button/Button';

const ContactForm = () => (
  <Form
    formName="contact-form"
    formTitle="Send us a message"
    successTitle="Message sent"
    successMessage="Thank you for your message. We will get back to you as soon as possible."
    errorTitle="Message not sent"
    errorMessage="Sorry your message has not been sent. Please try again."
  >
    <input type="hidden" name="form-name" value="contact-form" />
    <Input
      className="mb-5"
      label="Full Name"
      type="text"
      name="FullName"
      placeholder="Enter your full name…"
    />
    <Input
      className="mb-5"
      label="Email"
      type="email"
      name="Email"
      placeholder="Enter your email address…"
    />
    <Input
      optionalLabel
      className="mb-5"
      label="Phone"
      type="tel"
      name="Phone"
      placeholder="Enter your phone number…"
    />
    <TextArea
      className="mb-6"
      label="Message"
      name="Message"
      placeholder="Enter your message…"
    />
    <Button submit className="mx-auto" label="Send Message" />
  </Form>
);

export default ContactForm;
