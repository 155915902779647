import React from 'react';

import './ContactBlock.css';

import LocationIcon from '../Common/Icons/24/LocationIcon24';
import TimeIcon from '../Common/Icons/24/TimeIcon24';
import PhoneIcon from '../Common/Icons/24/PhoneIcon24';
import ContactForm from '../ContactForm/ContactForm';

const ContactBlock = () => (
  <div className="l-container l-container--sm l-container--no-mobile-gutter">
    <section className="c-contact-block">
      <aside className="c-contact-block__sidebar">
        <div>
          <span className="text-xl font-bold">Head Office</span>
          <div className="flex mt-3">
            <LocationIcon className="fill-current text-brand-primary" />
            <address className="not-italic text-brand-medium ml-3">
              120 Larch Street
              <br />
              Sudbury, ON, P3E 1C2
            </address>
          </div>
        </div>

        <div className="mt-10">
          <span className="text-xl font-bold">Office Hours</span>
          <div className="flex mt-3">
            <TimeIcon className="fill-current text-brand-primary" />
            <p className="text-brand-medium ml-3">
              Monday to Friday
              <br />9 a.m. to 5 p.m.
            </p>
          </div>
        </div>

        <div className="mt-10">
          <span className="text-xl font-bold">Phone Number</span>
          <div className="flex mt-3">
            <PhoneIcon className="fill-current text-brand-primary" />
            <a
              href="tel:+1-705-673-3000"
              className="text-brand-medium hover:text-brand-primary ml-3"
            >
              (705) 673-3000
            </a>
          </div>
        </div>

        <hr className="background-border-color my-8 lg:my-10" />

        <div>
          <span className="text-sm font-bold">After Hours Emergency</span>
          <div className="flex items-center mt-3 text-sm">
            <PhoneIcon className="fill-current text-brand-primary" />
            <a
              href="tel:+1-705-673-3000"
              className="text-brand-medium hover:text-brand-primary ml-3"
            >
              (705) 673-3000
            </a>
          </div>
        </div>
      </aside>
      <div className="c-contact-block__main">
        <ContactForm />
      </div>
    </section>
  </div>
);

export default ContactBlock;
