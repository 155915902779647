import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Common/Layout/Layout';
import Seo from '../components/Seo';
import Hero from '../components/Common/Hero/Hero';
import ContactBlock from '../components/ContactBlock/ContactBlock';

const ContactPage = () => (
  <Layout>
    <Seo
      title="Contact"
      description="Have a question or comment? We'd love to hear from you!"
    />
    <Hero
      extend
      background={
        <StaticImage
          src="../images/large-hero-bg.jpg"
          layout="fullWidth"
          placeholder="blurred"
          quality="90"
          alt=""
        />
      }
    >
      <h1 className="h-page-hero-title">Contact</h1>
    </Hero>
    <ContactBlock />
  </Layout>
);

export default ContactPage;
